import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, iif, of } from 'rxjs';
import { ENV } from '@environments/environment.provider';
import { filter, map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UserProfile } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  /**
   * IMPORTANT NOTE: Everything in this service is verifying whether
   * the user is an admin on the Alert Management app, NOT on the Activity Feed app.
   * May need to expand functionality later to choose which application the admin
   * check is taking place for.
   */

  private isZonarAdmin$ = new BehaviorSubject<boolean>(null);
  isZonarAdminObs$ = this.isZonarAdmin$.asObservable();
  private isCompanyAdmin$ = new BehaviorSubject<boolean>(null);
  isCompanyAdminObs$ = this.isCompanyAdmin$.asObservable();
  isAnyAdmin$ = this.isZonarAdminObs$.pipe(
    filter((isAdmin) => isAdmin !== null),
    switchMap((isZonarAdmin) => {
      return this.isCompanyAdminObs$.pipe(
        filter((isAdmin) => isAdmin !== null),
        map((isCompanyAdmin) => {
          return isCompanyAdmin || isZonarAdmin;
        })
      );
    })
  );
  private allUserProfiles$ = new ReplaySubject<any[]>(1);
  allUserProfilesObs$ = this.allUserProfiles$.asObservable();

  constructor(@Inject(ENV) private env: any, private _httpClient: HttpClient) {}

  updateIsZonarAdminValue(val) {
    this.isZonarAdmin$.next(val);
  }

  updateIsCompanyAdminValue(val) {
    this.isCompanyAdmin$.next(val);
  }

  updateAllUserProfilesValue(val) {
    this.allUserProfiles$.next(val);
  }

  /**
   * If the user is a company admin, then they need to match these criteria in order to be granted admin permissions:
   * 1. `roles` array needs to contain object which has `id` property equal to the roleId parameter in the environment file
   * 2. `companyId` in user profile needs to exist and match the currently selected company in the UI
   * 3. `applicationId` matches current application's ID
   * 4. `isAllDivisions$` value needs to be `true`
   * @param currentSelectedCompany the currently selected company on the UI
   */
  checkIfCompanyAdmin$(currentSelectedCompany): Observable<boolean> {
    return this.allUserProfilesObs$.pipe(
      map((allUserProfiles) => {
        return this.getCurrentProfile(allUserProfiles, currentSelectedCompany);
      }),
      switchMap((profile) => {
        return iif(
          () => !profile,
          of(false),
          this._isAllDivisions$(currentSelectedCompany).pipe(
            map((allDivisions) => {
              return !!allDivisions && profile.roles.some((role) => role.id === this.env.alertManagementAdminConfigs.roleId);
            })
          )
        );
      })
    );
  }

  /**
   * Check whether there is a unique profile for this application ID and company ID.
   * If so, return it. If not, return undefined.
   * @param allUserProfiles a list of all the user's userprofiles
   * @param currentSelectedCompany the currently selected company on the UI
   * @returns a matching user profile, or `undefined` if there is no matching user profile
   */

  getCurrentProfile(allUserProfiles, currentSelectedCompany): UserProfile | undefined {
    return allUserProfiles.find(
      (profile) =>
        profile.applicationId === this.env.alertManagementAdminConfigs.applicationId &&
        !!profile.companyId &&
        currentSelectedCompany.value === profile.companyId
    );
  }

  /**
   * If the user is a Zonar admin, then they need to match these criteria in order to be granted admin permissions:
   * 1. `roles` array needs to contain object which has `id` property equal to the roleId parameter in the environment file
   * 2. `companyId` needs to be null
   * 3. `applicationId` matches current application's ID
   * @param profile the current user profile being checked
   */

  isZonarAdmin(profile) {
    return (
      profile.roles.some((role) => role.id === this.env.alertManagementAdminConfigs.roleId) &&
      !profile.companyId &&
      profile.applicationId === this.env.alertManagementAdminConfigs.applicationId
    );
  }

  /**
   * Validates that each legacy division ID is found in the collection of divisions from any of the user's userprofiles
   *
   * @param currentSelectedCompany the currently selected company on the UI
   * @returns boolean value (wrapped in Observable) of whether or not the allDivisions parameter for the user is true
   */
  private _isAllDivisions$(currentSelectedCompany): Observable<boolean> {
    return this._httpClient
      .get<any>(`${this.env.coreEntityApiBase.url}/companies/${currentSelectedCompany.value}/divisions?type=LEGACY`)
      .pipe(
        switchMap((legacyDivisions) => {
          const filteredDivisions = legacyDivisions.map((val) => {
            return val.id;
          });
          return this.allUserProfilesObs$.pipe(
            map((allUserProfiles) => {
              return filteredDivisions.every((divisionOfInterest) => {
                return allUserProfiles.some((profile) => {
                  return profile.divisions.includes(divisionOfInterest);
                });
              });
            })
          );
        })
      );
  }
}
