<div ngClass.gt-sm="app-container-desktop" ngClass.lt-md="app-container-mobile" fxLayout="column" fxFill class="hiddenOverflowX">
  <zui-sidenav [sidenavParams]="sidenavParams" [header]="sidenavHeader" 
    (emitSelectedCompany)="setCurrentCompanyByCompanySelector($event)">
    <div class="sidenav-container app-bg-color" fxFlex>

      <zui-sidenav-header class="header-bar-sidenav" [title]=""
        (menuToggled)="onSideNavMobileMenuButtonToggled()"></zui-sidenav-header>

      <div class="content-container app-bg-color" fxFlex>
        <router-outlet></router-outlet>
      </div>
    </div>
  </zui-sidenav>
</div>