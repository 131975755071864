import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivityAlertsService } from '@services/activity-alerts.service';
import { Company } from 'src/app/models/company.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { catchError, debounceTime, distinctUntilChanged, filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { Observable, Subject, throwError, ReplaySubject } from 'rxjs';
import { cloneDeep } from 'lodash';
export interface Filter {
  name: string;
}

interface zuiDropdown {
  title: string;
  value: string;
}

@Component({
  selector: 'app-filter-tags',
  templateUrl: './filter-tags.component.html',
  styleUrls: ['./filter-tags.component.scss']
})
export class FilterTagsComponent implements OnInit {
  private _onDestroy$: Subject<void> = new Subject<void>();
  constructor(public fb: FormBuilder, public activityAlertService: ActivityAlertsService, private _store: Store<AppState>) {}
  public divisions = new FormControl();
  filtersFormGroup: FormGroup = this.fb.group({});
  @Output() postSelectedDivision = new EventEmitter();
  @Output() selectedFilters = new EventEmitter();

  divisionList$ = new ReplaySubject<any[]>(1);

  public statusList: Array<zuiDropdown> = [
    {
      title: 'Open',
      value: 'open'
    },
    {
      title: 'Closed',
      value: 'closed'
    }
  ];
  public severityList: Array<zuiDropdown> = [
    {
      title: 'Critical',
      value: '1'
    },
    {
      title: 'Minor',
      value: '2'
    }
  ];
  selectedCompany: Company;
  public divisionList = [];

  onSelectedFilter($event) {
    const divisions_data = $event
      .map((item) => {
        return item.value;
      })
      .join(',');
    this.selectedFilters.emit({ division: divisions_data });
  }

  onSelectedStatusFilter($event) {
    const statusData = $event
      .map((item) => {
        return item.value;
      })
      .join(',');
    this.selectedFilters.emit({ status: statusData });
  }

  onSelectedSeverityFilter($event) {
    const severityData = $event
      .map((item) => {
        return item.value;
      })
      .join(',');
    this.selectedFilters.emit({ severity: severityData });
  }

  private _getCurrentCompany$(): Observable<Company> {
    return this._store.select((state) => state.product).pipe(filter((company) => !!company));
  }

  private _handleCompanyChange$(): Observable<void> {
    return this._getCurrentCompany$().pipe(
      map((x) => cloneDeep(x)),
      distinctUntilChanged((prev, curr) => prev.value === curr.value),
      map((company) => {
        this.populateDivisionListOptions(company);
      })
    );
  }

  populateDivisionListOptions(selectedCompany: Company): void {
    this.activityAlertService
      .getAlerts$(undefined, selectedCompany)
      .pipe(
        switchMap((response) => {
          const divisionOptions = [];
          response.filters?.divisions.map((result) => divisionOptions.push({ title: result.name, value: result.id }));
          this.divisionList$.next(divisionOptions);
          return divisionOptions;
        }),
        catchError((err) => {
          return throwError(err);
        }),
        first()
      )
      .subscribe();
  }

  private _initializeDropdownFilters() {
    const defaultStatus = this.statusList.find((c) => c.title == 'Open');
    this.filtersFormGroup = this.fb.group({
      divisions: [null],
      status: [[defaultStatus.value]],
      severity: []
    });
  }

  ngOnInit(): void {
    this._initializeDropdownFilters();
    this._handleCompanyChange$().pipe(debounceTime(5000), takeUntil(this._onDestroy$)).subscribe();
  }
}
