import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivityAlertsService } from '@services/activity-alerts.service';
import { ReverseGeocoderService } from '@services/reverse-geocoder.service';
import { ReverseGeocoderResponse } from '../../models/reverse-geocoder-response.model';
import { getAddressCityStateZipText, getCityStateZipText } from '@app/shared/utilities';
import { Company } from '../../models/company.model';
import { AppState } from 'src/app/app.state';
import { Store } from '@ngrx/store';
import { GOOGLE_MAPS_BASE_URI, INSTANT_EVENT_TYPE_VALUE } from '@shared/constants';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-basic-alert',
  templateUrl: './basic-alert.component.html',
  styleUrls: ['./basic-alert.component.scss']
})
export class BasicAlertComponent implements OnInit {
  @Input() alertData;
  @Output() watchRemoved = new EventEmitter();
  public alertLocation;
  public watchDetails = false;
  public showId;
  googleMapsLinkText: string;
  googleMapsUri: string;
  public selectedCompany: Company;
  readonly INSTANT_EVENT_TYPE_VALUE = INSTANT_EVENT_TYPE_VALUE;
  reverseGeocode$;
  severityToIconMap = {
    1: 'error',
    2: 'warning'
  };

  constructor(
    public activityAlertService: ActivityAlertsService,
    public httpClient: HttpClient,
    public reverseGeocoderService: ReverseGeocoderService,
    private store: Store<AppState>
  ) {
    this.store
      .select((state) => state.product)
      .subscribe((selectedCompany) => {
        this.selectedCompany = selectedCompany;
      });
  }

  ngOnInit(): void {
    this.alertLocation = this.alertData?._source?.data.location;
    this.reverseGeocode$ = this._getReverseGeocode$(this.alertLocation).pipe(
      map((address: ReverseGeocoderResponse) => {
        return this._buildGoogleMapsInfo(address);
      }),
      catchError(() => {
        return throwError('Failed to get location. Try closing and re-opening this section to retry.');
      }),
      shareReplay(1)
    );
  }

  private _buildGoogleMapsInfo(geocodeAddress) {
    const googleMapsLinkText = this._getGoogleMapsLinkText(geocodeAddress);
    const geocodeData = geocodeAddress.data && geocodeAddress.data[0];
    const googleMapUri = this._getGoogleMapsUri(geocodeData ? googleMapsLinkText : geocodeAddress);
    return {
      text: googleMapsLinkText,
      uri: googleMapUri
    };
  }

  private _getReverseGeocode$(location) {
    return this.reverseGeocoderService.getReverseGeocode(location?.latitude, location?.longitude);
  }

  private _getGoogleMapsUri(geoCodeAddress) {
    const locationQuery =
      geoCodeAddress.latitude && geoCodeAddress.longitude ? `${geoCodeAddress.latitude},${geoCodeAddress.longitude}` : geoCodeAddress;
    return GOOGLE_MAPS_BASE_URI + locationQuery;
  }

  private _getGoogleMapsLinkText(geocode) {
    const geocodeData = geocode.data && geocode.data[0];
    if (geocodeData) {
      return geocodeData.address ? getAddressCityStateZipText(geocodeData) : getCityStateZipText(geocodeData);
    } else {
      return 'View in Google Maps';
    }
  }

  onPostWatch(id) {
    try {
      this.showId = id;
      this.watchDetails = false;
      this.activityAlertService
        .postWatch$(id, this.selectedCompany)
        .pipe(
          catchError(() => {
            this.showId = null;
            this.watchDetails = true;
            throw new Error('Post Watch failed');
          })
        )
        .subscribe();
      return;
    } catch (error) {
      console.log('Error on post Watch api', error);
      this.watchDetails = true;
    }
  }

  onDeleteWatch(id) {
    try {
      this.showId = null;
      this.watchDetails = true;
      this.activityAlertService.deleteWatch$(id, this.selectedCompany).subscribe(
        (response) => {
          if (response) {
            this.watchRemoved.emit();
          }
        },
        (error) => {
          this.watchDetails = false;
          this.showId = id;
          return error;
        }
      );
    } catch (error) {
      console.log('Got service error', error);
      this.watchDetails = false;
      this.showId = id;
    }
  }

  onCurrentLocation(source) {
    try {
      const url = environment.zonarApps.gtcxMapsBaseUrl + '/assets/' + source.context.entity_ids.asset_id + '/live';
      window.open(url, '_self');
    } catch (error) {
      console.log('Could not open Zonar map for asset');
    }
  }
}
