<div class="container u-margin__top--medium">
  <mat-card class="u-margin__bottom--medium">
    <span *ngIf="divisionList$ | withLoading | async as divisions">
      <ng-template [ngIf]="divisions.value">
        <zui-searchable-dropdown
          label="Locations"
          [options]="divisions.value"
          [isMultiple]="true"
          [z_formGroup]="filtersFormGroup"
          [selectOptionsControlName]="'divisions'"
          classIdentifier="driver-dropdown-select-panel"
          (selected)="onSelectedFilter($event)"
          class="u-margin__right--normal"
        >
        </zui-searchable-dropdown>
      </ng-template>
      <ng-template [ngIf]="divisions.error"
        ><div class="dropdown-error-container u-margin__bottom--medium">Error loading divisions</div></ng-template
      >
      <ng-template [ngIf]="divisions.loading">
        <ngx-skeleton-loader
          count="1"
          class="u-margin__right--normal"
          [theme]="{
            'border-radius': '5px',
            height: '36px',
            width: '204px',
            'background-color': '#E3E3E3',
            'margin-top': '4px'
          }"
        ></ngx-skeleton-loader>
      </ng-template>
    </span>
    <zui-searchable-dropdown
      label="Status"
      [options]="statusList"
      [isMultiple]="true"
      [z_formGroup]="filtersFormGroup"
      [selectOptionsControlName]="'status'"
      classIdentifier="driver-dropdown-panel"
      (selected)="onSelectedStatusFilter($event)"
      class="u-margin__right--normal"
    >
    </zui-searchable-dropdown>
    <zui-searchable-dropdown
      label="Severity"
      [options]="severityList"
      [z_formGroup]="filtersFormGroup"
      [isMultiple]="true"
      [selectOptionsControlName]="'severity'"
      classIdentifier="driver-dropdown-panel"
      (selected)="onSelectedSeverityFilter($event)"
    >
    </zui-searchable-dropdown>
  </mat-card>
</div>
