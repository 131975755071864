import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthHttpInterceptor, AuthModule, AuthService} from '@auth0/auth0-angular';
import {FaviconsService} from '@zonar-ui/core';
import {environment} from 'src/environments/environment';
import {permissions} from 'src/environments/shared';
import {ZonarUiAuthModule, ZonarUiPermissionsModule, ZonarOwnerIdInterceptor } from '@zonar-ui/auth';
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ZonarUiAnalyticsService, ZonarUIAnalyticsTokens, ZonarUiTestingTokens} from '@zonar-ui/analytics';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    AuthModule,
    ZonarUiAuthModule,
    ZonarUiPermissionsModule
  ], providers: [
    {provide: 'applicationId', useValue: environment.auth.applicationId},
 {provide: 'defaultPermPrefix', useValue: permissions.defaultPermPrefix},
 {provide: 'routePermissions', useValue: permissions.routes},
 {provide: 'redirectUrl', useValue: permissions.defaultRedirectUrl},
 {provide: 'logoutReturnToUrl',  useValue: environment.appUrl},
 {provide: 'environment', useValue: environment.authEnv},
 {provide: 'region', useValue: environment.region},
 { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
 { provide: 'region', useValue: environment.region},
 { provide: HTTP_INTERCEPTORS, useClass: ZonarOwnerIdInterceptor, multi: true },
    FaviconsService,
    AuthService,
    // TODO: Build out Auth0 application in dev, QA, and Prod tenants.
    // TODO: Onboard to Core API for your new application to be able to build permissions.

    // Symbols required for authentication
    {
      provide: 'appUrl', useValue: environment.appUrl
    },
    {provide: 'audience', useValue: environment.auth.audience},
    {provide: 'clientID', useValue: environment.auth.clientID},
    {provide: 'domain', useValue: environment.auth.domain},
    {provide: 'applicationId', useValue: environment.auth.applicationId},
    {provide: 'environment', useValue: environment.authEnv},
    {provide: 'useRefreshTokens', useValue: environment.auth.useRefreshTokens},
    // Symbols required for permissions.
    {provide: 'defaultPermPrefix', useValue: permissions.defaultPermPrefix},
    {provide: 'defaultZonarRole', useValue: environment.auth.defaultZonarRole},
    {provide: 'routePermissions', useValue: permissions.routes},
    {provide: 'redirectUrl', useValue: permissions.defaultRedirectUrl},
    // Analytics settings
    { provide: ZonarUIAnalyticsTokens.ID, useValue: environment.googleTagManager.id },
    { provide: ZonarUIAnalyticsTokens.AUTH, useValue: environment.googleTagManager.auth },
    { provide: ZonarUIAnalyticsTokens.ENVIRONMENT, useValue: environment.googleTagManager.environment },
    { provide: ZonarUiTestingTokens.CONTAINER_ID, useValue: environment.googleOptimize.gOptId },
    {provide: 'pendoParams', useValue: environment.pendo},
    ZonarUiAnalyticsService
  ]
})
export class CoreModule { }
