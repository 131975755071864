import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivityAlertsService } from '@services/activity-alerts.service';
import { getTimeAgoString } from '@shared/utilities';
import { HttpClient } from '@angular/common/http';
import { distinctUntilChanged, map, filter, tap, concatMap, takeUntil, skip, take } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, merge, Observable, Subject } from 'rxjs';
import { ZonarUiNotificationsService } from '@zonar-ui/notifications';
import { ErrorCodes } from '@shared/constants/error-codes';
import { Store } from '@ngrx/store';
import { AppState } from '@app/app.state';
import { Company } from 'src/app/models/company.model';
import { cloneDeep } from 'lodash';
import { Alert, WatchedAlert } from '@app/models';
import { MyAlert, UnreadMyAlerts } from '@app/models/my-alerts.model';
import { MyAlertsService } from '@app/services/my-alerts.service';
import { FeatureToggleService } from '@app/services/feature-toggle.service';
import { AdminService } from '@app/services/admin.service';
import { ENV } from '@environments/environment.provider';

@Component({
  selector: 'app-activity-feed',
  templateUrl: './activity-feed.component.html',
  styleUrls: ['./activity-feed.component.scss']
})
export class ActivityFeedComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<void> = new Subject<void>();

  public isEndOfAlertsStream = false;
  public isEndOfMyAlertStream = false;
  public selectedOptions = {};

  isAdmin$ = this._adminService.isAnyAdmin$;
  alertManagementBaseUrl = this.env.zonarApps.alertManagementBaseUrl;

  from = 20;
  to = 40;

  fromMyAlert = 20;
  toMyAlert = 40;

  isHideUnreadMyAlerts = true;

  // If there are too few posts in the stream, the skeleton loader will remain on, even though
  // no load event has been triggered, because the user must scroll to check for new events.
  // To fix this, the number of alerts in the initially loaded alerts is checked against this
  // number and if it is fewer than this number, then the scroll event is disabled.
  // In an ideal future, this would be automatically calculated based on window height.
  MAX_NUMBER_OF_POSTS_FOR_AUTO_SCROLL_TRIGGER = 3;

  NUMBER_OF_ADDITIONAL_POSTS_TO_LOAD = 20;
  NUMBER_OF_ADDITIONAL_MY_ALERT_POSTS_TO_LOAD = 20;

  isClearingFilters = false;

  constructor(
    public activityAlertService: ActivityAlertsService,
    public myAlertService: MyAlertsService,
    public httpClient: HttpClient,
    private _notifications: ZonarUiNotificationsService,
    private _store: Store<AppState>,
    private _featureToggleService: FeatureToggleService,
    private _adminService: AdminService,
    @Inject(ENV) private env: any
  ) {}

  allAlerts$: BehaviorSubject<Array<Alert>> = new BehaviorSubject(null);
  allMyAlert$: BehaviorSubject<Array<MyAlert>> = new BehaviorSubject(null);
  watchedAlerts$: BehaviorSubject<Array<WatchedAlert>> = new BehaviorSubject(null);
  currentCompany$ = new BehaviorSubject<Company | null>(null);
  unreadMyAlerts$: BehaviorSubject<UnreadMyAlerts> = new BehaviorSubject(null);
  displayMyAlerts = false;

  isMyAlertsTabFeatureToggleEnabled$ = this._featureToggleService.isFeatureEnabled('my-alerts').pipe(takeUntil(this._onDestroy$));
  isManageAlertsButtonFeatureToggleEnabled$ = this._featureToggleService
    .isFeatureEnabled('manage-alerts')
    .pipe(takeUntil(this._onDestroy$));

  /**
   * Waits until all tab-based feature toggle checks are finished before tab group
   * is loaded to ensure proper tab ordering
   *
   * Add any new tab-based feature toggles to the array in combineLatest(), and create
   * a new variable in order to show/hide the tab (e.g. isMyAlertsTabVisible)
   *
   * @returns Returns true at end of Observable chain to ensure that mat-tab-group is always shown
   */

  allTabsLoaded$: Observable<boolean> = combineLatest([this.isMyAlertsTabFeatureToggleEnabled$]).pipe(
    map(([isMyAlertsToggleEnabled]) => {
      this.isMyAlertsTabVisible = Boolean(isMyAlertsToggleEnabled);
    }),
    map(() => {
      return true;
    }),
    takeUntil(this._onDestroy$)
  );
  isMyAlertsTabVisible = false;

  ngOnInit(): void {
    this._featureToggleService.initializeDevCycle();
    this._getCurrentCompany$()
      .pipe(
        concatMap((company) =>
          merge(this._populateAlertsStream$(company), this._populateMyAlertsStream$(company), this._populateUnreadMyAlertsStream$(company))
        ),
        take(1)
      )
      .subscribe();

    this._handleCompanyChange$().pipe(takeUntil(this._onDestroy$)).subscribe();
  }

  private _getAlertsFromApi$(selectedCompany: Company): Observable<Array<Alert>> {
    return this.activityAlertService.getAlerts$(this.selectedOptions, selectedCompany).pipe(
      map((alerts) => {
        const formattedAlerts = this._populateTimeAgoText(alerts.results);
        return formattedAlerts;
      })
    );
  }

  private _getMyAlertsFromApi$(selectedCompany: Company): Observable<Array<MyAlert>> {
    return this.myAlertService.getMyAlerts$(this.selectedOptions, selectedCompany).pipe(
      map((alerts) => {
        const formattedAlerts = this._populateTimeAgoTextForMyAlert(alerts.results);
        return formattedAlerts;
      })
    );
  }

  private _getUnreadMyAlertsFromApi$(selectedCompany: Company, reset: boolean): Observable<void> {
    return this.myAlertService.getUnreadMyAlerts$(selectedCompany, reset).pipe(
      map((unreadMyAlerts) => {
        this.isHideUnreadMyAlerts = unreadMyAlerts.newAlertCount > 0 ? false : true;
        unreadMyAlerts.newAlertCount = unreadMyAlerts.newAlertCount < 100 ? unreadMyAlerts.newAlertCount : '99+';
        this.unreadMyAlerts$.next(unreadMyAlerts);
      })
    );
  }

  public translateMyAlertToAlert(myAlert: MyAlert) {
    // get the date and time string in local format
    const timestamp = new Date(myAlert._source['@timestamp']);
    const get_local_timestamp = new Date(timestamp.getTime() - timestamp.getTimezoneOffset() * 60 * 1000);
    const dateTimeString = get_local_timestamp.toLocaleString('en-US', { timeZone: 'UTC' });

    // split userProfileIds into 2 objects at position 1
    const sliceAt = 1;
    const userArr = Object.entries(myAlert._source.userProfileIds);
    const first_user_object = Object.fromEntries(userArr.slice(0, sliceAt));
    const remaining_user_object = Object.fromEntries(userArr.slice(sliceAt));

    // get number of remaining user profile
    const length_of_remaining_user_object = Object.keys(remaining_user_object).length;

    if (length_of_remaining_user_object == 0) {
      return {
        ...myAlert,
        _source: myAlert._source.source_event,
        first_user: first_user_object,
        sendVia: myAlert._source.sendVia,
        timestamp: dateTimeString
      };
    }

    return {
      ...myAlert,
      _source: myAlert._source.source_event,
      first_user: first_user_object,
      remaining_user: remaining_user_object,
      number_remaining_user: length_of_remaining_user_object,
      sendVia: myAlert._source.sendVia,
      timestamp: dateTimeString
    };
  }

  // eslint-disable-next-line
  private _getWatchedAlertsFromApi$(selectedCompany: Company): Observable<Array<any>> {
    return this.activityAlertService.getWatch$(selectedCompany).pipe(
      map(
        (alerts) => {
          const formattedAlerts = this._populateTimeAgoText(alerts.results);
          return formattedAlerts;
        },
        (err) => {
          this.showErrorNotifications(err, this.handleWatchRetry());
        }
      )
    );
  }

  private _populateTimeAgoText(alerts: Array<Alert>) {
    const formattedAlerts = alerts.map((a) => {
      return { ...a, time_ago_text: getTimeAgoString(a._source.data.start_time, false) };
    });
    return formattedAlerts;
  }

  // TODO
  private _populateTimeAgoTextForMyAlert(alerts: Array<MyAlert>) {
    const formattedAlerts = alerts.map((a) => {
      return { ...a, time_ago_text: getTimeAgoString(a._source.source_event.data.start_time, false) };
    });
    return formattedAlerts;
  }

  private _getCurrentCompany$(): Observable<Company> {
    return this._store
      .select((state) => state.product)
      .pipe(
        filter((company) => !!company),
        tap((company) => this._setCurrentCompany(company))
      );
  }

  private _populateAlertsStream$(company: Company): Observable<void> {
    this.isEndOfAlertsStream = false;
    return this._getAlertsFromApi$(company).pipe(
      map((alerts) => {
        if (alerts.length < this.MAX_NUMBER_OF_POSTS_FOR_AUTO_SCROLL_TRIGGER) {
          this.isEndOfAlertsStream = true;
        }
        this.allAlerts$.next(alerts);
      })
    );
  }

  private _populateMyAlertsStream$(company: Company): Observable<void> {
    this.isEndOfMyAlertStream = false;
    return this._getMyAlertsFromApi$(company).pipe(
      map((alerts) => {
        if (alerts.length < this.MAX_NUMBER_OF_POSTS_FOR_AUTO_SCROLL_TRIGGER) {
          this.isEndOfMyAlertStream = true;
        }
        this.allMyAlert$.next(alerts);
      })
    );
  }

  private _populateUnreadMyAlertsStream$(company: Company): Observable<void> {
    return this._getUnreadMyAlertsFromApi$(company, false);
  }

  refreshWatchedAlertsStream = () => {
    this.currentCompany$
      .pipe(
        filter((company) => !!company),
        concatMap((company: Company) => {
          return this._populateWatchedAlertsStream$(company);
        }),
        take(1)
      )
      .subscribe();
  };

  private _populateWatchedAlertsStream$(company: Company): Observable<void> {
    return this._getWatchedAlertsFromApi$(company).pipe(map((alerts) => this.watchedAlerts$.next(alerts)));
  }

  private _handleCompanyChange$(): Observable<void> {
    return this._getCurrentCompany$().pipe(
      skip(1),
      map((x) => cloneDeep(x)),
      distinctUntilChanged((prev, curr) => {
        return prev.value === curr.value;
      }),
      concatMap((company) => {
        this._clearFilters();
        this._clearAlertsStream();
        this._clearMyAlertStream();
        this._clearUnreadMyAlertsStream();
        return merge(this._populateAlertsStream$(company), this._populateMyAlertsStream$(company), this._populateUnreadMyAlertsStream$(company));
      })
    );
  }

  // TODO (AA-341): Fix this hack that relies on destroying the filter component, then
  // re-creating it. This will need to be replaced with a more dynamic
  // and robust solution.
  private _clearFilters() {
    this.isClearingFilters = true;
    setTimeout(() => (this.isClearingFilters = false));
  }

  private _setCurrentCompany(company: Company) {
    this.currentCompany$.next(company);
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.unsubscribe();
  }

  selectedTabValue = (event) => {
    switch (event.tab.textLabel) {
      case 'Watching':
        this.refreshWatchedAlertsStream();
        break;

      case 'My Alerts':
        this.fromMyAlert = 20;
        this.toMyAlert = 40;
        this._clearMyAlertStream();
        this.onMyAlertsChange(this.selectedOptions);
        this.readUnreadMyAlerts(this.currentCompany$.value);
        break;

      case 'All Alerts':
        this.from = 20;
        this.to = 40;
        this._clearWatchedAlertsStream();
        this.onAlertsChange(this.selectedOptions);
        break;

      default:
        break;
    }
    if (event.tab.textLabel !== 'Watching') {
      this.from = 20;
      this.to = 40;
      this._clearWatchedAlertsStream();
      this.onAlertsChange(this.selectedOptions);
    } else {
      this.refreshWatchedAlertsStream();
    }
  };

  onScroll(): void {
    this.currentCompany$
      .pipe(
        concatMap((selectedCompany) => {
          return combineLatest([
            this.allAlerts$,
            this.activityAlertService.getScrollAlerts$(this.from, this.to, this.selectedOptions, selectedCompany)
          ]);
        }),
        take(1)
      )
      .subscribe(([currentAlerts, newAlerts]) => {
        if (newAlerts.results.length < this.NUMBER_OF_ADDITIONAL_POSTS_TO_LOAD) {
          this.isEndOfAlertsStream = true;
        }
        const combinedAlertsList = currentAlerts.concat(newAlerts.results);
        const formattedAlertsList = this._populateTimeAgoText(combinedAlertsList);
        this.allAlerts$.next(formattedAlertsList);
        this.from = this.to;
        this.to += this.NUMBER_OF_ADDITIONAL_POSTS_TO_LOAD;
      });
  }

  onMyAlertScroll(): void {
    this.currentCompany$
      .pipe(
        concatMap((selectedCompany) =>
          combineLatest(
            this.allMyAlert$,
            this.myAlertService.getMyAlerts$(this.selectedOptions, selectedCompany, {
              from_item: this.fromMyAlert,
              to_item: this.toMyAlert
            })
          )
        ),
        take(1)
      )
      .subscribe(([currentAlerts, newAlerts]) => {
        if (newAlerts.results.length < this.NUMBER_OF_ADDITIONAL_MY_ALERT_POSTS_TO_LOAD) {
          this.isEndOfMyAlertStream = true;
        }
        const combinedAlertsList = currentAlerts.concat(newAlerts.results);
        const formattedAlertsList = this._populateTimeAgoTextForMyAlert(combinedAlertsList);
        this.allMyAlert$.next(formattedAlertsList);
        this.fromMyAlert = this.to;
        this.toMyAlert += this.NUMBER_OF_ADDITIONAL_MY_ALERT_POSTS_TO_LOAD;
      });
  }

  private _clearAlertsStream(): void {
    this.allAlerts$.next(null);
  }

  private _clearWatchedAlertsStream(): void {
    this.watchedAlerts$.next(null);
  }

  private _clearMyAlertStream(): void {
    this.allMyAlert$.next(null);
  }

  private _clearUnreadMyAlertsStream(): void {
    this.isHideUnreadMyAlerts = true;
    this.unreadMyAlerts$.next(null);
  }

  onAlertsChange($event?) {
    this.currentCompany$
      .pipe(
        filter((company) => !!company),
        concatMap((company) => {
          const optionsParam = $event ?? undefined;
          this._clearAlertsStream();
          return this.activityAlertService.getAlerts$(optionsParam, company);
        }),
        map(
          (response) => {
            if (response.results.length < this.MAX_NUMBER_OF_POSTS_FOR_AUTO_SCROLL_TRIGGER) {
              this.isEndOfAlertsStream = true;
            }
            const formattedAlerts = this._populateTimeAgoText(response.results);
            this.allAlerts$.next(formattedAlerts);
          },
          (err) => {
            this.showErrorNotifications(err, this.handleActivityRetry);
          }
        ),
        take(1)
      )
      .subscribe();
  }

  onMyAlertsChange($event?) {
    this.currentCompany$
      .pipe(
        filter((company) => !!company),
        concatMap((company) => {
          const optionsParam = $event ?? undefined;
          this._clearMyAlertStream();
          return this.myAlertService.getMyAlerts$(optionsParam, company);
        }),
        map(
          (response) => {
            if (response.results.length < this.MAX_NUMBER_OF_POSTS_FOR_AUTO_SCROLL_TRIGGER) {
              this.isEndOfMyAlertStream = true;
            }
            const formattedAlerts = this._populateTimeAgoTextForMyAlert(response.results);
            this.allMyAlert$.next(formattedAlerts);
          },
          (err) => {
            this.showErrorNotifications(err, this.handleActivityRetry);
          }
        ),
        take(1)
      )
      .subscribe();
  }

  readUnreadMyAlerts(company: Company) {
    this._getUnreadMyAlertsFromApi$(company, true).subscribe();
  }

  onGetSelectedFilters($event) {
    if ($event) {
      Object.assign(this.selectedOptions, $event);
      this.onAlertsChange(this.selectedOptions);
      this.onMyAlertsChange(this.selectedOptions);
      this.from = 20;
      this.to = 40;
      this.fromMyAlert = 20;
      this.toMyAlert = 40;
    } else if ($event === '') {
      this.selectedOptions = undefined;
      this.onAlertsChange();
      this.onMyAlertsChange();
    } else {
      this.selectedOptions = undefined;
    }
  }

  handleActivityRetry = () => {
    this.onAlertsChange();
    this.onMyAlertsChange();
    // manually dismiss the notification after the button is clicked
    this._notifications.dismiss();
  };

  handleWatchRetry = () => {
    // manually dismiss the notification after the button is clicked
    this._notifications.dismiss();
  };

  showErrorNotifications = (error, handler) => {
    const notificationText = ErrorCodes.ERROR_MSG.find((err) => {
      let errTitle = undefined;
      if (err.code === error.status) {
        errTitle = err;
      }
      return errTitle;
    });
    this.errorHandler(notificationText, error, handler);
  };

  errorHandler = (errorFormattedObj, error, handler) => {
    switch (error.status) {
      case 400:
        this._notifications.openError(errorFormattedObj.title, '', 10);
        break;
      case 500:
      case 501:
      case 502:
        this._notifications.openError(errorFormattedObj.title, '', 10, {
          onButtonClick: handler,
          buttonText: 'Retry!'
        });
        break;
      case 404:
        this._notifications.openError(errorFormattedObj.title, '', 10);
        break;
      case 401:
        this._notifications.openError(errorFormattedObj.title, '', 10);
        break;
      default:
        this._notifications.openError('Could not connect at the moment', '', 10);
        break;
    }
  };

  trackByFn(index) {
    return index;
  }

  redirectToAlertsManagement() {
    window.location.href = this.alertManagementBaseUrl;
  }
}
