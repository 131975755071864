import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AdminService } from '@app/services/admin.service';

@Injectable()
export class CheckForAdminInterceptor implements HttpInterceptor {
  constructor(private _adminService: AdminService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('/userprofiles') && request.params.get('embed') === 'roles') {
      return next.handle(request).pipe(
        filter((event) => Object.prototype.hasOwnProperty.call(event, 'body')),
        map((event) => {
          if (event instanceof HttpResponse) {
            this._adminService.updateAllUserProfilesValue(event.body);
            const isAdmin = event.body.some((profile) => {
              return this._adminService.isZonarAdmin(profile);
            });
            this._adminService.updateIsZonarAdminValue(isAdmin);
          }
          return event;
        })
      );
    }
    return next.handle(request);
  }
}
