import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { appName } from 'src/environments/shared';
import { PendoService, ZonarUiAnalyticsService } from '@zonar-ui/analytics';
import { I18nService, TranslateService } from '@zonar-ui/i18n';
import { SIDENAV_FOOTER_CONFIG, SIDENAV_HEADER_CONFIG, SIDENAV_MENU_CONFIG } from './shared/config/sidenav.config';
import { Store } from '@ngrx/store';
import { Company } from './models/company.model';
import { AppState } from './app.state';
import { debounceTime, filter, map, take, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { DatadogService } from './services/datadog.service';
import { AuthService } from '@auth0/auth0-angular';
import { FaviconsService } from '@zonar-ui/core';
import { PermissionsService } from '@zonar-ui/auth';
import { AdminService } from './services/admin.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<void> = new Subject<void>();
  loading = true;
  title: string = appName;
  translationsNotLoaded = true;

  sidenavHeader = SIDENAV_HEADER_CONFIG;
  sidenavMenu = SIDENAV_MENU_CONFIG;
  sidenavFooter = SIDENAV_FOOTER_CONFIG;
  sidenavParams = {
    mobileOpened: true,
    footerOffset: false
  };

  currentCompany$: Subject<Company> = new Subject<Company>();

  currentCompany: Company | null = null;

  TIME_BETWEEN_COMPANY_CHANGES = 1000;

  constructor(
    private router: Router,
    private zuiAnalytics: ZonarUiAnalyticsService,
    private permissionsService: PermissionsService,
    private pendoService: PendoService,
    public translateService: TranslateService,
    private i18nService: I18nService,
    private favicon: FaviconsService,
    private store: Store<AppState>,
    private datadogService: DatadogService,
    private authService: AuthService,
    private _adminService: AdminService
  ) {
    if (this.translationsNotLoaded) {
      this.translateService.use(this.i18nService.getLanguageInUse()).subscribe(() => {
        this.translationsNotLoaded = false;
      });
    }
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    this.zuiAnalytics.addGtmToDom();

    this.currentCompany$
      .pipe(
        debounceTime(250),
        map((company: Company) => {
          this.pendoService.initialize();
          this.updateCompanyAdminValue(company);
          return this.updateCurrentCompany(company);
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const tag = { event: 'page', pageName: event.url };
        this.zuiAnalytics.pushTag(tag);
      }
    });

    // when running locally via `ng serve`, the `version.json` asset will not exist, so we disable version checking via
    // fetching this `version.json` static content
    if (!environment.devTools) {
      this.authService.isAuthenticated$.pipe(filter((isAuthenticated) => Boolean(isAuthenticated))).subscribe(() => {
        // Start DataDog session replay recording
        this.datadogService.startSessionReplayRecording();
      });
    }
  }

  onSideNavMobileMenuButtonToggled() {
    this.sidenavParams.mobileOpened = true;
  }

  debounce(func, wait, immediate = false) {
    let timeout;
    return function () {
      // eslint-disable-next-line
      let context = this;
      // eslint-disable-next-line
      const args = arguments;
      const later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  updateCurrentCompany(company: Company) {
    if (!this.currentCompany || this.currentCompany.value !== company.value) {
      this.currentCompany = company;
      this.store.dispatch({
        type: 'CURRENT_COMPANY',
        payload: <Company>{
          value: this.currentCompany.value,
          title: this.currentCompany.title
        }
      });
    }
    return this.currentCompany;
  }

  updateCompanyAdminValue(company) {
    this._adminService
      .checkIfCompanyAdmin$(company)
      .pipe(
        map((isCompanyAdmin) => {
          this._adminService.updateIsCompanyAdminValue(isCompanyAdmin);
        }),
        take(1)
      )
      .subscribe();
  }

  setCurrentCompanyByCompanySelector(company: Company) {
    this.currentCompany$.next(company);
  }

  ngOnDestroy() {
    this.datadogService.stopSessionReplayRecording();
    this._onDestroy$.next();
    this._onDestroy$.unsubscribe();
  }
}
