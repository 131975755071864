import { Company } from '../models/company.model';

export const ADD_PRODUCT = 'ADD_PRODUCT';
export const CURRENT_COMPANY = 'CURRENT_COMPANY';
export const SELECTED_COMPANY = 'SELECTED_COMPANY';

export function addProductReducer(state: Company = undefined, action) {
  switch (action.type) {
    case ADD_PRODUCT:
      return action.payload;
    case CURRENT_COMPANY:
      return action.payload;
    case SELECTED_COMPANY:
      return action.payload;
    default:
      return state;
  }
}
