import { Injectable } from '@angular/core';
import { User } from '@auth0/auth0-spa-js';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { statusType } from '@datadog/browser-logs/bundle/datadog-logs';
import { environment } from '../../environments/environment';
import { appName, appVersion } from '../../environments/shared';
import { PermissionsService } from '@zonar-ui/auth';
import { first } from 'rxjs/operators';
import { isLocalDevEnv } from '../shared/utilities/utilities';
@Injectable({
  providedIn: 'root'
})
export class DatadogService {
  private user: User;
  constructor(private permissions: PermissionsService) {
    if (!isLocalDevEnv()) {
      // enable real user monitoring for all non-local (deployed) environments
      datadogRum.init({
        applicationId: environment.datadog.applicationId,
        clientToken: environment.datadog.clientToken,
        env: environment.name,
        service: appName,
        version: appVersion,
        sessionSampleRate: 100,
        premiumSampleRate: 100,
        replaySampleRate: 100,
        silentMultipleInit: true,
        trackUserInteractions: true,
        allowedTracingOrigins: [/https:\/\/.*\.zonarsystems\.net/, `${environment.apiBase}/geocoder/api/v1/reverse`],
        site: environment.datadog.siteUrl,
        useSecureSessionCookie: true,
        useCrossSiteSessionCookie: true,
        defaultPrivacyLevel: 'mask-user-input'
      });

      datadogLogs.init({
        clientToken: environment.datadog.clientToken,
        env: environment.name,
        service: appName,
        silentMultipleInit: true,
        site: environment.datadog.siteUrl,
        forwardErrorsToLogs: false,
        sampleRate: 100
      });

      this.permissions.users$.pipe(first((users) => Boolean(users?.length))).subscribe((user) => {
        this.user = user[0];
        datadogRum.setUser({
          id: this.user.id,
          email: this.user.email,
          sub: this.user.sub
        });
      });
    }
  }

  addRumAction(name: string, context?: object | undefined): void {
    if (!isLocalDevEnv()) {
      datadogRum.addAction(name, context);
    }
  }

  addRumError(error: Error, context?: object | undefined): void {
    if (!isLocalDevEnv()) {
      datadogRum.addError(error, context);
    }
  }

  addRumTiming(name: string, time?: number | undefined): void {
    if (!isLocalDevEnv()) {
      datadogRum.addTiming(name, time || Date.now());
    }
  }

  getRumUser(): User | undefined {
    return this.user;
  }

  startSessionReplayRecording(): void {
    datadogRum.startSessionReplayRecording();
  }

  stopSessionReplayRecording(): void {
    datadogRum.stopSessionReplayRecording();
  }

  log(message: string, attributes, status: statusType = 'info'): void {
    if (!isLocalDevEnv()) {
      datadogLogs.logger.log(message, attributes, status);
    }
  }
}
