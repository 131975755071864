import { Pipe, PipeTransform } from "@angular/core";
import { MILES_PER_METER } from "../constants";
import { round } from "lodash";
import { AlertMetadata } from "src/app/models";

@Pipe({
    name: "customUnitConversion",
  })

export class CustomUnitConversionPipe implements PipeTransform {
    transform(input: AlertMetadata): string {
        let output: number;
        switch(input.key) {
          case "engine_hours":
            output = parseFloat(input['value']);
            return `${roundNumbers(output)} hours`;
          case "odometer":
            output = parseFloat(input['value']);
            return `${roundNumbers(output * MILES_PER_METER)} miles`;
          case "oil_pressure":
            output = parseFloat(input['value']);
            return `${roundNumbers(output)} kPa`;
          case "coolant_temperature":
            output = parseFloat(input['value']);
            return `${roundNumbers(output)} F`;
          case "battery_voltage":
            output = parseFloat(input['value']);
            return `${roundNumbers(output)} volts`;
          case "occurrence_count":
            output = parseFloat(input['value']);
            return `${roundNumbers(output)} time(s)`;
          default:
            return `${input['value']}`;
        }
    }
}

function roundNumbers(value: number, minDecimals = 1, maxDecimals = 2): string {
    const roundedValue = round(value, maxDecimals).toString();
    
    const parts = roundedValue.split('.');
    if (parts.length === 1) {
        parts.push('0');
    }
    
    const [integerPart, decimalPart] = parts;
    if (decimalPart.length >= maxDecimals) {
        minDecimals = maxDecimals;
    }
    const adjustedDecimalPart = decimalPart.length < minDecimals
        ? decimalPart + '0'.repeat(minDecimals - decimalPart.length)
        : decimalPart.slice(0, minDecimals);

    return `${integerPart}.${adjustedDecimalPart}`;
}
