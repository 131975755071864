// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  region: 'NA',
  appUrl: 'https://activityfeed.staging.zonarsystems.net/',
  authEnv: 'production',
  name: 'staging',
  adminRole: 'a0ae2891-38d5-4832-99bb-b1c6d883aa84',
  apiBase: 'https://activities-and-alerts-api.staging.zonarsystems.net',
  devTools: true,
  zonarApps: {
    gtcxMapsBaseUrl: 'https://maps.zonarsystems.net',
    alertManagementBaseUrl: 'https://alertmanagement.zonarsystems.net'
  },
  alertManagementAdminConfigs: {
    roleId: 'a0ae2891-38d5-4832-99bb-b1c6d883aa84',
    applicationId: '2c51e6ee-5b9a-48c8-b45e-da68991a4c27'
  },
  auth: {
    clientID: 'sxzqpzbitLy0ttXPpumBG62vgbHPZVw3',
    domain: 'zonar-login.auth0.com',
    audience: 'https://api.zonarsystems.net',
    applicationId: '65e7599a-493f-436a-8cf0-e544c30e4a25',
    defaultZonarRole: '63356e3d-ca4d-4888-99c0-2907023bb7bb',
    useRefreshTokens: true
  },
  datadog: {
    applicationId: 'd9510c6e-b793-4156-abbd-c755919f1866',
    clientToken: 'pub7c67ecc6ecb6ec6a494008ed10faf740',
    siteUrl: 'us5.datadoghq.com'
  },
  devCycle: {
    clientId: 'dvc_client_0c8f57e7_4bcf_4324_9392_906c70568cf2_fe3fe36'
  },
  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: 'mvIS1MKUWMyBOIZODYerVw',
    environment: 'env-5'
  },
  googleOptimize: {
    gOptId: null,
    powerOnAbTestId: null
  },
  pendo: {
    url: 'https://cdn.pendo.io/agent/static/'
  },
  coreEntityApiBase: {
    url: 'https://api.zonarsystems.net/core/v1'
  },
  i18n: {
    supportedLanguages: ['en-US'],
    defaultLanguage: 'en-US'
  },
  featureFlags: {
    refreshActivityBtn: true
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
