<mat-card class="alert-card" id="{{ alertData._id }}">
  <div class="flex flex-row items-center justify-start">
    <div
      class="severity-badge"
      [ngClass]="{
        'severity-badge--level-1': alertData._source.data.severity === 1,
        'severity-badge--level-2': alertData._source.data.severity === 2
      }"
    >
      <mat-icon class="severity-badge__icon">{{ severityToIconMap[alertData._source.data.severity] }}</mat-icon>
    </div>
    <div class="flex flex-row items-center u-margin__right--small">
      <mat-icon class="secondary-metadata__icon u-margin__right--extra-tiny">local_shipping</mat-icon>
      <span class="secondary-metadata__text">{{
        alertData._source.data.entity_map &&
          alertData._source.context.entity_ids.asset_id &&
          alertData._source.data.entity_map[alertData._source.context.entity_ids.asset_id].name
      }}</span>
    </div>
    <div class="flex flex-row items-center u-margin__right--small">
      <mat-icon class="secondary-metadata__icon u-margin__right--extra-tiny">access_time</mat-icon>
      <span class="secondary-metadata__text">{{ alertData.time_ago_text }}</span>
    </div>
  </div>

  <div class="main-content">
    <div class="main-content__title">{{ alertData._source.data.title }}</div>
    <div class="main-content__description-text u-text--black">{{ alertData._source.data.description }}</div>
    <div class="flex flex-row items-center" *ngIf="alertData._source.data.event_status !== INSTANT_EVENT_TYPE_VALUE">
      <span class="main-content__description-text u-text--gray u-margin__right--tiny">Status:</span>
      <span class="main-content__description-text u-text--blue">{{
        alertData._source.data.event_status === 'closed' ? 'Resolved' : 'Active'
      }}</span>
    </div>
    <span [style.display]="accordionItem.expanded ? 'none' : ''" class="content-divider"> </span>
    <cdk-accordion>
      <cdk-accordion-item #accordionItem="cdkAccordionItem" role="button" tabindex="0" [attr.aria-expanded]="accordionItem.expanded">
        <div role="region" [style.display]="accordionItem.expanded ? '' : 'none'">
          <div *ngIf="accordionItem.expanded">
            <button mat-stroked-button id="currentLocation" class="current-location-btn" (click)="onCurrentLocation(alertData['_source'])">
              <mat-icon class="u-margin__right--extra-tiny u-text--blue">map</mat-icon>
              <span class="u-text--blue">Current Location</span>
            </button>
            <table class="primary-metadata-table">
              <tr class="primary-metadata-table__row" *ngIf="alertLocation?.latitude || alertLocation?.longitude">
                <td class="primary-metadata-table__detail-title">Event location</td>
                <td class="primary-metadata-table__detail-value">
                  <ng-container *ngIf="reverseGeocode$ | withLoading | async as reverseGeocode">
                    <ng-template [ngIf]="reverseGeocode.value">
                      <a class="link" href="{{ reverseGeocode.value.uri }}" target="_blank">
                        {{ reverseGeocode.value.text }}
                        <mat-icon class="link__icon">open_in_new</mat-icon>
                      </a>
                    </ng-template>
                    <ng-template [ngIf]="reverseGeocode.error">{{ reverseGeocode.error }}</ng-template>
                    <ng-template [ngIf]="reverseGeocode.loading">Loading...</ng-template>
                  </ng-container>
                </td>
              </tr>
              <tr class="primary-metadata-table__row" *ngFor="let metadata of alertData._source.data.metadata">
                <td class="primary-metadata-table__detail-title">{{ metadata.label }}</td>
                <td class="primary-metadata-table__detail-value">{{ metadata | customUnitConversion }}</td>
              </tr>
            </table>
            <div class="primary-notification" *ngIf="alertData.first_user">
              <mat-card class="mat-card-notification">
                <mat-card-content>
                  <mat-icon class="secondary-metadata__icon u-margin__right--extra-tiny">notifications_active</mat-icon>
                  <span
                    class="main-content__description-text u-text--gray u-margin__right--tiny"
                    *ngFor="let user of alertData.first_user | keyvalue"
                  >
                    Notification sent to: {{ user.value }}
                  </span>
                  <div class="tooltip" *ngIf="alertData.remaining_user">
                    + {{ alertData.number_remaining_user }} more
                    <span class="tooltiptext" *ngFor="let user of alertData.remaining_user | keyvalue"> , {{ user.value }}</span>
                  </div>
                  <span class="main-content__description-text u-text--gray u-margin__right--tiny"> {{ alertData.timestamp }} via </span>
                  <mat-icon class="secondary-metadata__icon u-margin__right--extra-tiny">email</mat-icon>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
        <div class="actions-container">
          <button mat-button (click)="accordionItem.toggle()">
            <mat-icon *ngIf="!accordionItem.expanded"> keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="accordionItem.expanded"> keyboard_arrow_up</mat-icon>
            {{ !accordionItem.expanded ? 'View' : 'Hide' }} Details
          </button>
        </div>
      </cdk-accordion-item>
    </cdk-accordion>
  </div>
</mat-card>
